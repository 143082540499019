import { useRouter } from 'vue-router';
import { useQuickView } from '@/route';
import { useHighlightedTask } from './useHighlightedTask';
import { useLegacyBridge } from '@/module/legacy';
import { useExperimentR2403 } from '@/api';

const useTaskDetailsSymbol = Symbol('useTaskDetails');

export function provideTaskDetails() {
  const router = useRouter();
  const { openQuickView, closeQuickView, quickViewItem } = useQuickView();
  const { activeTaskDetailsId } = useLegacyBridge();
  const { setHighlightedTask } = useHighlightedTask();
  const { isExpR2403Variation } = useExperimentR2403();

  const task = computed(() => quickViewItem.value);
  const taskLoaded = shallowRef(false);
  const taskMovementCounter = shallowRef(0);

  const topElement = shallowRef(undefined);
  const bottomElement = shallowRef(undefined);
  const attachmentsListElement = shallowRef(undefined);
  const historyElement = shallowRef(undefined);
  const commentListElement = shallowRef(undefined);
  const reminderElement = shallowRef(undefined);
  const dependenciesElement = shallowRef(undefined);
  const dependenciesTabElement = shallowRef(undefined);
  const timelogsElement = shallowRef(undefined);
  const proofsElement = shallowRef(undefined);
  const subtaskInputElement = shallowRef(undefined);
  const commentItemElements = ref({});
  const isSubtasksInputFocused = shallowRef(false);
  const isDependencyDialogOpen = shallowRef(false);
  const autofocusTaskName = shallowRef(false);

  const sections = {
    comments: {
      element: commentListElement,
      children: commentItemElements,
    },
    files: {
      element: attachmentsListElement,
      options: { block: 'start' },
    },
    history: {
      element: historyElement,
    },
    reminders: {
      element: reminderElement,
    },
    dependencies: {
      element: dependenciesElement,
      options: { block: 'start' },
    },
    dependenciesTab: {
      element: dependenciesTabElement,
    },
    timelogs: {
      element: timelogsElement,
    },
    proofs: {
      element: proofsElement,
    },
    reply: {
      element: commentListElement,
    },
    subtasks: {
      element: subtaskInputElement,
    },
    top: {
      element: topElement,
      options: { block: 'start', behavior: 'instant' },
    },
    bottom: {
      element: bottomElement,
      options: { block: 'end' },
    },
  };

  const hash = computed(() => router.currentRoute.value?.hash.slice(1));

  function setHash(param) {
    router.replace({ hash: param ? `#${param}` : '' });
  }

  function clearTask() {
    activeTaskDetailsId.value = undefined;
    setHighlightedTask(undefined);
  }

  function closeTask() {
    clearTask();
    closeQuickView();
  }

  /**
   *
   * @param {object} params - The parameters for opening the task.
   * @param {object} params.task - The task to open.
   * @param {string} [params.hash] - (optional) The hash to be added to the url.
   * @param {boolean} [params.editTaskName] - (optional) Whether to focus the task name
   */
  function openTask({ task: _task, hash: _hash, editTaskName: _editTaskName }) {
    autofocusTaskName.value = _editTaskName;
    openQuickView(`/tasks/${_task.id}`, { hash: _hash, item: _task });
  }

  function scrollToElement(element, scrollOptions = {}) {
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', ...scrollOptions });
    }
  }

  function scrollToSection(section) {
    const { element, options } = sections[section];
    scrollToElement(element?.value, options);
  }

  function scrollToComment(commentId) {
    const element = sections.comments.children.value[commentId];
    scrollToElement(element, { block: 'end' });
  }

  function scrollToSectionDelayed(section) {
    const { element, options } = sections[section];
    setTimeout(() => scrollToElement(element?.value, options), 500);
  }

  const isOpen = computed(() => Boolean(task.value));

  provide(useTaskDetailsSymbol, {
    // Read-only - use `openTask` to display the details of a different task
    task,
    openTask,
    closeTask,
    hash,
    taskLoaded,
    taskMovementCounter,
    setHash,
    clearTask,
    isSubtasksInputFocused,
    isDependencyDialogOpen,
    isOpen,
    scrollToSection,
    scrollToComment,
    topElement,
    bottomElement,
    attachmentsListElement,
    historyElement,
    commentListElement,
    commentItemElements,
    sections,
    reminderElement,
    subtaskInputElement,
    dependenciesElement,
    dependenciesTabElement,
    timelogsElement,
    proofsElement,
    scrollToSectionDelayed,
    isExpR2403Variation,
    autofocusTaskName,
  });
}

export function useTaskDetails() {
  return inject(useTaskDetailsSymbol);
}
