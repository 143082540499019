/* eslint-disable no-param-reassign */
import { DateTime } from 'luxon';
import { useI18n } from '@/util';

/**
 * The camel case function receives a text and detects the starting letter of each word.
 * It then converts it to uppercase if its **not** the first word.
 * Finally it removes all the empty space to join the words and create the final came cased result.
 * @param {String} text - The text or sentence to be camel cased.
 * @returns {String} - The camel cased version of the text param.
 */
function camelCase(str) {
  return str
    .trim()
    .replace(/\s+(.)/g, (match, group1) => group1.toUpperCase())
    .replace(/^(.)/, (match, group1) => group1.toLowerCase());
}

export function useReportTableColumns() {
  const { t } = useI18n();
  const cache = new Map();
  const width = 140;

  function getRawValue(key, column, bag) {
    bag[column.value?.customfieldId ?? key] = column.value;
  }

  function getRawValueAsArray(key, column, bag) {
    bag[key] = !column.value || Array.isArray(column.value) ? column.value : [column.value];
  }

  function getDateValue(key, column, bag) {
    bag[key] = DateTime.fromISO(column.value);
  }

  function getDateUTCValue(key, column, bag) {
    bag[key] = DateTime.fromISO(column.value, { zone: 'utc' });
  }

  function getDatesValue(key, column, bag) {
    bag[key] = {
      start: DateTime.fromISO(column.value?.start, { zone: 'utc' }),
      end: DateTime.fromISO(column.value?.end, { zone: 'utc' }),
    };
  }

  function getNamesAsStringValue(key, column, bag) {
    bag[key] = column.value?.map((item) => item.name).join(', ') ?? '';
  }

  function getKeyValue(key, column, bag) {
    bag[key] = column.value?.[key] ?? null;
  }

  function getArrayHeadOrRawValue(key, column, bag) {
    bag[key] = (Array.isArray(column.value) ? column.value[0] : column.value) ?? null;
  }

  function getNameValueWithId(key, column, bag) {
    bag[key] = column.value?.name ?? null;
    bag[`${key}Id`] = column.value?.id ?? null;
  }

  function getArrayHeadNameValue(key, column, bag) {
    bag[key] = column.value?.[0]?.name ?? null;
  }

  /**
   * Maps the teams column to a more readable format.
   * Exclusively used for the teams column.
   */
  function getTeamsValue(key, column, bag) {
    bag[key] = column.value?.map((team) => ({
      bgColor: team.color || 'var(--lsds-a-color-surface-dark)',
      id: team.id,
      // a few icons are missing
      // icon: `lsi-${team.icon}`
      alt: team.name,
      iconColor: 'var(--lsds-a-color-text-on-primary)',
      name: team.name,
      src: team.logoUrl || undefined,
    }));
  }

  function getTaskCompletionValues(key, column, bag) {
    bag[key] = column.value?.taskCompletion ?? null;
    bag.numberOfActiveTasks = column.value?.numberOfActiveTasks ?? null;
  }

  // only numbers for now
  const columnTypeMap = {
    'active projects': 'number',
    'actual utilization': 'number',
    'allocated time': 'number',
    'allocated time percentage': 'number',
    'assigned tasks': 'number',
    'available time': 'number',
    'available time percentage': 'number',
    'billable time': 'number',
    'billable time percentage': 'number',
    'billable total': 'number',
    'billable utilization': 'number',
    'completed tasks': 'number',
    cost: 'number',
    difference: 'number',
    'estimated time': 'number',
    'estimated utilization': 'number',
    'forecasted billable total': 'number',
    'forecasted cost': 'number',
    'forecasted billable utilization': 'number',
    'forecast profit number': 'number',
    'forecast profit percentage': 'number',
    'logged time': 'number',
    'logged vs estimated time': 'number',
    'non billable time': 'number',
    'non billable utilization': 'number',
    'number of clients': 'number',
    'overdue tasks': 'number',
    progress: 'number',
    'profit number': 'number',
    'profit percentage': 'number',
    revenue: 'number',
    'task completion': 'number',
    'task total': 'number',
    'time estimate': 'number',
    'time left': 'number',
    'time logged': 'number',
    'total cost': 'number',
    'total working hours': 'number',
    'unavailable time': 'number',
    'unavailable time percentage': 'number',
    'utilization target percentage': 'number',
  };

  const items = {
    'active projects': {
      translation: t('Active projects'),
      icon: 'lsi-project',
      width,
    },
    'actual utilization': {
      translation: t('Total utilization'),
      icon: 'lsi-timer',
      width,
    },
    assignee: {
      translation: t('Assignee'),
      icon: 'lsi-user',
      width: 120,
      sortable: false,
    },
    'assigned tasks': {
      translation: t('Assigned tasks'),
      icon: 'lsi-task',
      width,
    },
    'available time': {
      translation: t('Available time'),
      icon: 'lsi-time',
      width,
    },
    board: {
      translation: t('Board'),
      icon: 'lsi-board-column',
      width,
      accessor: getRawValueAsArray,
      sortable: false,
    },
    'billable time': {
      translation: t('Billable time'),
      icon: 'lsi-time',
      width,
    },
    'billable total': {
      translation: t('Billable total'),
      icon: 'lsi-billable',
      width,
    },
    'billable utilization': {
      translation: t('Billable utilization'),
      icon: 'lsi-timer',
      width,
    },
    'budget used': {
      translation: t('Budget used'),
      icon: 'lsi-billable',
      width,
    },
    budget: {
      translation: t('Budget'),
      icon: 'lsi-billable',
      width: 250,
    },
    category: {
      translation: t('Category'),
      icon: 'lsi-bookmark',
      width,
      accessor: getArrayHeadOrRawValue,
      sortable: false,
    },
    company: {
      translation: t('Company'),
      icon: 'lsi-company',
      width: 150,
    },
    'completed by': {
      translation: t('Completed by'),
      icon: 'lsi-task-complete',
      width,
    },
    'completed tasks': {
      translation: t('Completed tasks'),
      icon: 'lsi-task',
      width,
    },
    'created by': {
      translation: t('Created by'),
      icon: 'lsi-user',
      width,
    },
    dates: {
      translation: t('Dates'),
      icon: 'lsi-calendar',
      width,
      accessor: getDatesValue,
    },
    description: {
      translation: t('Description'),
      icon: 'lsi-tasklist-description',
      width,
    },
    difference: {
      translation: t('Difference'),
      icon: 'lsi-calendar',
      width,
    },
    'due date': {
      translation: t('Due date'),
      icon: 'lsi-calendar',
      width,
      accessor: getDateUTCValue,
    },
    'date created': {
      translation: t('Date created'),
      icon: 'lsi-calendar',
      width,
      accessor: getDateValue,
    },
    'completed date': {
      translation: t('Completed date'),
      icon: 'lsi-calendar',
      width,
      accessor: getDateValue,
    },
    'estimated time': {
      translation: t('Total estimated time'),
      icon: 'lsi-time',
      width,
    },
    'estimated utilization': {
      translation: t('Total estimated utilization'),
      icon: 'lsi-timer',
      width,
    },
    'forecast billable total': {
      translation: t('Forecasted billable total'),
      icon: 'lsi-billable',
      width,
    },
    'forecast cost': {
      translation: t('Forecasted cost'),
      icon: 'lsi-billable',
      width,
    },
    'forecast billable utilization': {
      translation: t('Forecasted billable utilization'),
      icon: 'lsi-listview-progress',
      width,
      slot: 'percentage',
    },
    'forecast profit number': {
      translation: t('Forecasted profit number'),
      icon: 'lsi-billable',
      width,
    },
    'forecast profit percentage': {
      translation: t('Forecasted profit percentage'),
      icon: 'lsi-listview-progress',
      width,
      slot: 'percentage',
    },
    health: {
      translation: t('Health'),
      icon: 'lsi-health',
      width: 250,
    },
    id: {
      translation: t('Id'),
      icon: 'lsi-number',
      width,
    },
    'last active': {
      translation: t('Last active'),
      icon: 'lsi-company',
      width,
      accessor: getDateValue,
    },
    'last updated': {
      translation: t('Last updated'),
      icon: 'lsi-calendar',
      width,
      accessor: getDateValue,
    },
    'latest update': {
      translation: t('Latest update'),
      icon: 'lsi-tasklist-description',
      width,
    },
    'logged time': {
      translation: t('Logged time'),
      icon: 'lsi-time',
      width,
    },
    'logged time vs estimated time': {
      translation: t('Logged time vs estimated time'),
      icon: 'lsi-calendar',
      width,
    },
    milestoneId: {
      translation: t('Milestone ID'),
      icon: 'lsi-milestone',
      width,
    },
    milestone: {
      translation: t('Milestone'),
      icon: 'lsi-milestone',
      width,
      accessor: getNameValueWithId,
    },
    'non billable time': {
      translation: t('Non-billable time'),
      icon: 'lsi-time',
      width,
    },
    'non billable utilization': {
      translation: t('Non-billable utilization'),
      icon: 'lsi-timer',
      width,
    },
    'original due date': {
      translation: t('Original due date'),
      icon: 'lsi-calendar',
      width,
      accessor: getDateUTCValue,
    },
    owner: {
      translation: t('Owner'),
      icon: 'lsi-user',
      width: 120,
    },
    'overdue tasks': {
      translation: t('Overdue tasks'),
      icon: 'lsi-task',
      width,
    },
    percentage: {
      translation: t('Percentage'),
      icon: 'lsi-listview-progress',
      width,
    },
    'percentage tasks completed': {
      translation: t('Percentage tasks completed'),
      icon: 'lsi-task',
      width,
      accessor: getKeyValue,
    },
    'number of active tasks': {
      translation: t('Number of active tasks'),
      icon: 'lsi-task',
      width,
      accessor: getKeyValue,
    },
    priority: {
      translation: t('Priority'),
      icon: 'lsi-priority',
      width,
    },
    private: {
      translation: t('Private'),
      icon: 'lsi-privacy',
      width,
    },
    progress: {
      translation: t('Progress'),
      icon: 'lsi-play',
      width,
    },
    projectId: {
      translation: t('Project ID'),
      icon: 'lsi-project',
      width,
    },
    project: {
      translation: t('Project'),
      icon: 'lsi-project',
      width,
      accessor: getNameValueWithId,
    },
    'profit number': {
      translation: t('Profit number'),
      icon: 'lsi-billable',
      width: 210,
    },
    'profit percentage': {
      translation: t('Profit percentage'),
      icon: 'lsi-listview-progress',
      width: 210,
      slot: 'percentage',
    },
    reminders: {
      translation: t('Reminders'),
      icon: 'lsi-timer',
      width,
    },
    sequence: {
      translation: t('Sequence'),
      icon: 'lsi-repeat',
      width,
    },
    repeats: {
      translation: t('Repeats'),
      icon: 'lsi-repeat',
      width,
    },
    start: {
      translation: t('Start date'),
      icon: 'lsi-calendar',
      width,
      accessor: getDateUTCValue,
    },
    end: {
      translation: t('End date'),
      icon: 'lsi-calendar',
      width,
      accessor: getDateUTCValue,
    },
    'start date': {
      translation: t('Start date'),
      icon: 'lsi-calendar',
      width,
      accessor: getDateUTCValue,
    },
    'end date': {
      translation: t('End date'),
      icon: 'lsi-calendar',
      width,
      accessor: getDateUTCValue,
    },
    status: {
      translation: t('Status'),
      icon: 'lsi-tooltip',
      width: 250,
    },
    tags: {
      translation: t('Tags'),
      icon: 'lsi-tag',
      width,
    },
    teams: {
      translation: t('Teams'),
      icon: 'lsi-group',
      width,
      accessor: getTeamsValue,
      sortable: false,
    },
    taskId: {
      translation: t('Task ID'),
      icon: 'lsi-task',
      width,
    },
    task: {
      translation: t('Task'),
      icon: 'lsi-task',
      width,
      accessor: getNameValueWithId,
    },
    'task completion': {
      translation: t('Task completion'),
      icon: 'lsi-task',
      width,
      accessor: getTaskCompletionValues,
    },
    'task list': {
      translation: t('Task list'),
      icon: 'lsi-tasklist',
      width,
      accessor: getNameValueWithId,
    },
    'task lists': {
      translation: t('Task lists'),
      icon: 'lsi-tasklist',
      width,
      accessor: getNamesAsStringValue,
    },
    'task total': {
      translation: t('Task total'),
      icon: 'lsi-task',
      width,
    },
    'time estimate': {
      translation: t('Time estimate'),
      icon: 'lsi-time',
      width,
    },
    'time left': {
      translation: t('Time left'),
      icon: 'lsi-calendar',
      width,
    },
    'time logged': {
      translation: t('Time logged'),
      icon: 'lsi-time',
      width,
    },
    'total cost': {
      translation: t('Total cost'),
      icon: 'lsi-billable',
      width,
    },
    'unavailable time': {
      translation: t('Unavailable time'),
      icon: 'lsi-time',
      width,
    },
    userId: {
      translation: t('User ID'),
      icon: 'lsi-user',
      width,
    },
    user: {
      translation: t('User'),
      icon: 'lsi-user',
      width,
    },
    'workflow stages': {
      translation: t('Stage'),
      icon: 'lsi-dashboard',
      width: 200,
      sortable: false,
    },
    statusDueDate: {
      translation: t('Status due date'),
      icon: 'lsi-calendar',
      width,
    },
    statusCompletedDate: {
      translation: t('Status completed date'),
      icon: 'lsi-calendar',
      width,
    },
    'number of clients': {
      translation: t('Number of clients'),
      icon: 'lsi-client',
      width,
      slot: 'integer',
    },
    'job role': {
      translation: t('Role'),
      icon: 'lsi-job-roles',
      width,
      accessor: getArrayHeadNameValue,
    },
    'estimated billable utilization': {
      translation: t('Estimated billable utilization'),
      icon: 'lsi-timer',
      width,
      slot: 'percentage',
    },
    'utilization percent target': {
      translation: t('Utilization % target'),
      icon: 'lsi-listview-progress',
      width,
      slot: 'percentage',
    },
    'estimated non billable utilization': {
      translation: t('Estimated non-billable utilization'),
      icon: 'lsi-timer',
      width,
      slot: 'percentage',
    },
    'billable time percentage': {
      translation: t('Billable time percentage'),
      icon: 'lsi-listview-progress',
      width,
      slot: 'percentage',
    },
    'available time percentage': {
      translation: t('Available time percentage'),
      icon: 'lsi-listview-progress',
      width,
      slot: 'percentage',
    },
    'unavailable time percentage': {
      translation: t('Unavailable time percentage'),
      icon: 'lsi-listview-progress',
      width,
      slot: 'percentage',
    },
    'total working hours': {
      translation: t('Total working hours'),
      icon: 'lsi-time',
      width,
      slot: 'time',
    },
    'allocated time': {
      translation: t('Allocated time'),
      icon: 'lsi-time',
      width,
      slot: 'time',
    },
    'allocated time percentage': {
      translation: t('Allocated time percentage'),
      icon: 'lsi-listview-progress',
      width,
      slot: 'percentage',
    },
  };

  const accessors = Object.keys(items).reduce((acc, key) => {
    const item = items[key];
    acc[key] = item.accessor ?? getRawValue;
    return acc;
  }, {});

  const keysCache = {};

  function transformColumnItem({ id, row }) {
    if (cache.has(id)) {
      return cache.get(id);
    }

    const bag = {
      id,
    };

    for (const column of row) {
      const accessor = accessors[column.name] ?? getRawValue;
      // eslint-disable-next-line no-multi-assign
      const key = (keysCache[column.name] ??= camelCase(column.name));

      accessor(key, column, bag);
    }

    cache.set(id, bag);

    return bag;
  }

  const baseTypeColumns = {
    milestone: {
      columnName: 'milestoneName',
      blankSlateMessage: t('No milestones available.'),
    },
    task: {
      columnName: 'taskName',
      blankSlateMessage: t('No tasks available.'),
    },
    user: {
      columnName: 'userName',
      blankSlateMessage: t('No users available.'),
    },
    project: {
      columnName: 'projectName',
      blankSlateMessage: t('No projects available.'),
    },
  };

  return { items, columnTypeMap, baseTypeColumns, transformColumnItem };
}
