import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  Legend,
  LineController,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  SubTitle,
  Decimation,
} from 'chart.js';
import Annotations from 'chartjs-plugin-annotation';

Chart.register(
  Title,
  Tooltip,
  Legend,
  Annotations,
  BarController,
  BarElement,
  CategoryScale,
  Decimation,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  SubTitle,
);
