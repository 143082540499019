<script setup>
defineProps({
  invoiceId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['close']);

const modelValue = defineModel({
  type: Boolean,
  default: false,
});

const FinanceInvoiceSummaryDialogPanel = defineAsyncComponent(() => import('./FinanceInvoiceSummaryDialogPanel.vue'));
</script>

<template>
  <WidgetDialog v-model="modelValue" fullscreen>
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <template #default="{ close }">
      <FinanceInvoiceSummaryDialogPanel
        :invoiceId="invoiceId"
        @close="
          close();
          emit('close');
        "
      />
    </template>
  </WidgetDialog>
</template>
